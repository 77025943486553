<template>
  <div class="container">
    <div class="logo-container">
      <img src="@/assets/live-brine-shrimp-logo-wide.png"
      alt="Live Brine Shrimp Logo"
      class="logo">
    </div>
    <div class="text-container">
      Please contact us to inquire regarding institutional or wholesale purchasing
      of Brine Shrimp and other live products.
    </div>
    <div class="contact-container">
      Phone Number: 386-345-3333<br>
      E-Mail: sales@northeastbrineshrimp.com
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #7bdcb5;
}
.logo {
  text-align: center;
  width: 350px;
  height: auto;
  object-fit: contain;
}
.logo-container {
  margin: 0;
  text-align: left;
  padding: 50px;
  width: 600px;
  height: 120px;

  @media (min-width: 600px) {
    margin: 0 auto;
  }
}
.text-container {
  padding-top: 50px;
  margin: 0 auto;
  width: 350px;
  height: auto;

  @media (min-width: 600px) {
    width: 600px;
    font-size: 24px;
  }
}
.contact-container {
  padding-top: 50px;
  margin: 0 auto;
  width: 350px;
  height: auto;

  @media (min-width: 600px) {
    width: 600px;
    font-size: 22px;
  }
}
</style>
